import { CSSReset, ThemeProvider } from '@chakra-ui/core'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { FacebookPixel } from '@components/FacebookPixel'
import { ModalProvider } from '@components/Modal/provider'
import CookieProvider from '@src/react-context/cookie-context'
import { PartnersProvider } from '@src/react-context/partners-context'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { SWRConfig } from 'swr'
import '../styles/globals.css'

export const theme = extendTheme({
  fonts: {
    heading: 'Inter, sans-serif',
    body: 'Inter, sans-serif',
  },
  colors: {
    gray: {
      50: '#F4F4F7',
      100: '#F0F0F0',
      150: '#DCDDE3',
      200: '#C6C8D1',
      300: '#B0B3C0',
      400: '#A0A4B3',
      500: '#7C8096',
      600: '#646981',
      700: '#4D5163',
      800: '#363843',
      900: '#0F2233',
    },
    blue: {
      50: '#EAF6FF',
      500: '#0D71F0',
      600: '#0A5AC0',
      800: '#052D5F',
    },
    orange: {
      500: '#FF9D2E',
    },
  },
})

function App({ Component, pageProps, gtmId, fbPixelId }) {
  useEffect(() => {
    if (gtmId) {
      TagManager.initialize({ gtmId })
    }
  }, [])
  return (
    <>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
        }}
      >
        <ThemeProvider>
          <ChakraProvider theme={theme}>
            <CookieProvider>
              <CSSReset />
              <ModalProvider openYourAccountAlert={pageProps?.openYourAccountAlert}>
                <PartnersProvider>
                  <Component {...pageProps} />
                </PartnersProvider>
              </ModalProvider>

              <script
                src={'https://unpkg.com/blip-chat-widget'}
                type={'text/javascript'}
                integrity={'sha384-ouECQb0S113KuvY42WfQQJyQG5GCD4zY6tLs6N4q7F/sPGHHk2W0RKmYG2HtCbr7'}
                crossOrigin={'anonymous'}
              ></script>
            </CookieProvider>
          </ChakraProvider>
        </ThemeProvider>
      </SWRConfig>
      <FacebookPixel fbPixelId={fbPixelId} />
    </>
  )
}

App.getInitialProps = async () => {
  return { gtmId: process.env.GTM_ID, fbPixelId: process.env.FB_PIXEL_ID }
}
export default App
